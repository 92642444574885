import Vue from 'vue'
import VueRouter from 'vue-router'
import enRoutes from './EN/index'
Vue.use(VueRouter)

const routes=[
    {
        path: '/',
        name: 'ZHhome',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/home/home.vue')
      },
      {
        path: '/ZH/download',
        name: 'ZHdownload',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/download/download.vue')
      },
      {
        path: '/ZH/merchandise',
        name: 'ZHmerchandise',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/merchandise/merchandise.vue')
      },
      {
        path: '/ZH/forex',
        name: 'ZHforex',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/forex/forex.vue')
      },
      {
        path: '/ZH/CFDS',
        name: 'ZHCFDS',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/CFDS/CFDS.vue')
      },
      {
        path: '/ZH/depositAndWithdraw',
        name: 'ZHdepositAndWithdraw',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/depositAndWithdraw/depositAndWithdraw.vue')
      },
      {
        path: '/ZH/accountService',
        name: 'ZHaccountService',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/accountService/accountService.vue')
      },
      {
        path: '/ZH/tradeTime',
        name: 'ZHtradeTime',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/tradeTime/tradeTime.vue')
      },
      {
        path: '/ZH/accountServicePolicy',
        name: 'ZHaccountServicePolicy',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/accountServicePolicy/accountServicePolicy.vue')
      },
      {
        path: '/ZH/marketAnalysis',
        name: 'ZHmarketAnalysis',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/marketAnalysis/marketAnalysis.vue')
      },
      {
        path: '/ZH/fincialCalendar',
        name: 'ZHfincialCalendar',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/fincialCalendar/fincialCalendar.vue')
      },
      {
        path: '/ZH/economicIndicators',
        name: 'ZHeconomicIndicators',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/economicIndicators/economicIndicators.vue')
      },
      {
        path: '/ZH/ConceptualTerms',
        name: 'ZHConceptualTerms',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/ConceptualTerms/ConceptualTerms.vue')
      },
      {
        path: '/ZH/partner',
        name: 'ZHpartner',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/partner/partner.vue')
      },
      {
        path: '/ZH/aboutUs',
        name: 'ZHaboutUs',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/aboutUs/aboutUs.vue')
      },
      {
        path: '/ZH/clause',
        name: 'ZHclause',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/clause/clause.vue')
      },
      {
        path: '/ZH/supervise',
        name: 'ZHsupervise',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/supervise/supervise.vue')
      },
      {
        path: '/ZH/details',
        name: 'ZHdetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/ZH/details/details.vue')
      },
      ...enRoutes
]

const router = new VueRouter({
    routes
  })
  
  export default router
  