import axios from "axios";
// 测试
//  let VUE_APP_API = "http://aeforexx.newmskjs.com";
//  正式
let VUE_APP_API = "https://cn-api1.aeforex-live.com";
// 本地
//  let VUE_APP_API = "http://192.168.110.160:8081";

// let VUE_APP_API = "http://aeforexadminapi.szrnet.xyz";
export async function ajax(param, url, post) {
  try{
    const { data } = await axios({
      method: post ? post : "post",
      url: `${VUE_APP_API}` + url,
      headers: { "Content-Type": "application/json; charset=utf-8" },
      data: param.data // 添加这一行来发送数据
    });
    return data;
  }catch(error){
    console.log(error);
    
  }
}
