import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Mixin from "./mixins/index";
import { ajax } from "./api/ajax";
import VueRouter from "vue-router";
import VueI18n from 'vue-i18n'


// 字体文件
// import zh_tran from "./utils/lang";
// Vue.prototype.$zh_tran = zh_tran;
Vue.prototype.$ajax = ajax;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueI18n)
Vue.mixin(Mixin);
const i18n = new VueI18n({
  locale: JSON.parse(localStorage.getItem('locales'))?JSON.parse(localStorage.getItem('locales')):'zh-CN', // 设置默认语言
  messages: {
    'zh-CN': require('./locales/zh.json'), // 导入语言文件
    'en': require('./locales/en.json'), // 导入英文文件
    'zh-TW': require('./locales/zh-TW.json') // 导入繁体中文文件
  }
})
Vue.prototype.$changeLocale = function (locale) {
  i18n.locale = locale
}
if (!window.sessionStorage.getItem("locale")) {
  window.sessionStorage.setItem("locale", '中文')
}
Vue.prototype.$goto = function (name) {
  if (this.$route.name == name) {
    return;
  }
  console.log(this.$route.path);
  this.$router.push({
    name: name,
  });
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

new Vue({
  router,
  i18n, // 将 i18n 实例添加到 Vue 实例中
  render: (h) => h(App),
}).$mount("#app");
