<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    
  </div>
</template>

<script>
export default {
  name: "App",
  // created(){
  //   const langtype=localStorage.getItem('lang')
  //   if(!langtype){
  //     localStorage.setItem('lang','cn')
  //   }else{
  //     if(langtype=='cn'){
  //       this.$goto('ZHhome')
  //     }else if(langtype=='en'){
  //       this.$goto('ENhome')
  //     }
  //   }
  // }
};
</script>

<style lang="less">
@import "../src/styles/global.less";
.discove {
  padding: 0px 20px;
}
</style>
