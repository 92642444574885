const enRoutes = [
  {
    path: "/EN/home",
    name: "ENhome",
    title: "home",
    component: () => import("../../views/EN/home/home.vue"),
  },
  {
    path: "/EN/forex",
    name: "ENforex",
    title: "Forex",
    type: "pro",
    component: () => import("../../views/EN/forex/forex.vue"),
  },
  {
    path: "/EN/commodity",
    name: "ENmerchandise",
    title: "Commodity",
    type: "pro",
    component: () => import("../../views/EN/commodity/commodity.vue"),
  },
  {
    path: "/EN/details",
    name: "ENdetails",
    title: "details",
    type: "",
    component: () => import("../../views/EN/details/details.vue"),
  },
  {
    path: "/EN/indices",
    name: "ENCFDS",
    title: "Indices",
    type: "pro",
    component: () => import("../../views/EN/indices/indices.vue"),
  },
  {
    path: "/EN/download",
    name: "ENdownload",
    title: "Download",
    type: "pro",
    component: () => import("../../views/EN/download/download.vue"),
  },
  {
    path: "/EN/funding",
    name: "ENdepositAndWithdraw",
    title: "Funding",
    type: "service",
    component: () => import("../../views/EN/funding/funding.vue"),
  },
  // {
  //   path: "/EN/calendar",
  //   name: "ENfincialCalendar",
  //   title: "Calendar",
  //   type: "market",
  //   component: () => import("../../views/EN/calendar/calendar.vue"),
  // },
  {
    path: "/EN/indicators",
    name: "ENeconomicIndicators",
    title: "Economic Indicators",
    type: "market",
    component: () => import("../../views/EN/indicators/indicators.vue"),
  },

  {
    path: "/EN/faq",
    name: "ENaccountService",
    title: "FAQ",
    type: "service",
    component: () => import("../../views/EN/faq/faq.vue"),
  },
  {
    path: "/EN/analysis",
    name: "ENmarketAnalysis",
    title: "Market Analysis",
    type: "market",
    component: () => import("../../views/EN/analysis/analysis.vue"),
  },
  {
    path: "/EN/hours",
    name: "ENtradeTime",
    title: "Trading Hours",
    type: "service",
    component: () => import("../../views/EN/hours/hours.vue"),
  },
  {
    path: "/EN/policy",
    name: "ENaccountServicePolicy",
    title: "Order Execution Policy",
    type: "service",
    component: () => import("../../views/EN/policy/policy.vue"),
  },
 
  {
    path: "/EN/glossary",
    name: "ENConceptualTerms",
    title: "Terms Glossary",
    type: "market",
    component: () => import("../../views/EN/glossary/glossary.vue"),
  },
  {
    path: "/EN/about",
    name: "ENaboutUs",
    title: "Introduce",
    type: "terms",
    component: () => import("../../views/EN/about/about.vue"),
  },
  {
    path: "/EN/terms",
    name: "ENclause",
    title: "Terms of Use",
    type: "terms",
    component: () => import("../../views/EN/terms/terms.vue"),
  },
  {
    path: "/EN/program",
    name: "ENpartner",
    title: "Liquidity Program",
    type: "par",
    component: () => import("../../views/EN/program/program.vue"),
  },
];
export default enRoutes;
